import React, { useMemo, useState } from "react"
import { graphql } from "gatsby"
import { useDispatch, useSelector } from "react-redux"
import SEO from "../../components/seo"

// 🎨 UI
import { Heading, Box, Flex } from "@chakra-ui/react"
import DetailPageWrapper from "../../components/Contents/DetailPageWrapper"
// import ZukunftsbildContent from "../../components/Contents/Zukunftsbild/ZukunftsbildContent"
import TOCParagraphButton from "../../components/Buttons/TOCParagraphButton"
import ScrollTopButton from "../../components/ScrollTopButton"
import Inhalt from "../../components/Contents/Inhalt"

// 🚀 Transition
import TransitionButton from "../../components/Buttons/TransitionButton"
import Transition from "../../components/Animation/Transition"
import { moveCurrentPageOut } from "../../components/Animation/PageTransition"
import WYSIWYGImageOverlay from "../../components/ContentModules/WYSIWYGImageOverlay"

export default function Zukunftsbild({ data, location }) {
  const sendActionToStore = useDispatch()
  const CURRENT_PATH = useSelector(state => state.currentPath)
  // console.log("PAAAAATH OUTSIDE", CURRENT_PATH)
  const LANG = useSelector(state => state.currentLang)
  const post = useMemo(() => data.wpZukunftsbild, [data.wpZukunftsbild])
  const settings = useMemo(() => data.wpModeSettings, [data.wpModeSettings])
  // console.log("ZUKUNFTSBILD RENDERED")
  // console.log("📯 POST", post)

  sendActionToStore({
    type: "change/translatedProjectSlug",
    payload: post.translated[0]?.slug,
  })

  // 🚀 Transitions Settings
  const [exit, setExit] = useState(false)
  const defaultInAnimation = location?.state
    ? location.state.direction
    : "right"
  const [AnimationDirection, setAnimationDirection] =
    useState(defaultInAnimation)

  // 🚀🧨 Transitions Redux Triggers
  const TRANSITION_INFO = useSelector(state => state.transition)
  if (TRANSITION_INFO.shouldExit && TRANSITION_INFO.direction) {
    setAnimationDirection(TRANSITION_INFO.direction)
    setExit(TRANSITION_INFO.shouldExit)
    sendActionToStore({
      type: `PageChangeTransition/ended`,
    })
  }

  return (
    <>
      <SEO title={post.title} />
      <ScrollTopButton />
      {/* <div className="fixed z-50">
        <WYSIWYGImageOverlay />
      </div> */}

      <Transition autoIn shouldExit={exit} direction={AnimationDirection}>
        <DetailPageWrapper>
          <Box position="absolute" top="-24px" left="80px">
            <TransitionButton
              text={settings.acf_zukunftsbilder_settings?.textButtonBack}
              link={LANG === "en" ? "/en" : "/"}
              targetPageInAnimation="left"
              onClick={() => {
                moveCurrentPageOut(sendActionToStore, "right")
              }}
            />
          </Box>

          {/* 👤 Header Detailseite */}
          <Box width={["100%", "100%", "100%", "100%"]}>
            <Heading
              as="h1"
              size="md"
              color="modeGray.500"
              mb="8px"
              maxWidth="640px"
            >
              {post.acf_zukunftsbilder_content.textSubheadline}
            </Heading>
            <Heading
              as="h2"
              size="2xl"
              mb={{ base: "32px", md: "32px" }}
              maxWidth="640px"
            >
              {post.title}
            </Heading>
          </Box>
          <Flex direction={["column", "column", "column", "row"]}>
            <Box width={["100%", "100%", "100%", "100%"]}>
              <Box
                fontWeight="medium"
                fontSize="lg"
                className="WYSIWYG"
                mb="40px"
                width={["100%", "100%", "100%", "100%"]}
                maxWidth="640px"
              >
                {post.acf_zukunftsbilder_content.textSummary}
              </Box>
              {/* 📰 Inhalt */}
              <Inhalt
                repeater={post.acf_content.repeaterDetails}
                smallWidth={true}
                projektZiel={true}
                galleryBelow={false}
                detailPage={true}
              />
            </Box>
          </Flex>

          <TOCParagraphButton
            paragraphs={post.acf_content.repeaterDetails}
            currentPath={CURRENT_PATH}
            title={settings.acf_global_settings?.textTocTitle}
          />
        </DetailPageWrapper>
      </Transition>
    </>
  )
}
export const query = graphql`
  query ZukunftsbildContentQuery($lang: String!, $slug: String!) {
    wpZukunftsbild(slug: { eq: $slug }, locale: { locale: { eq: $lang } }) {
      title
      translated {
        slug
      }
      acf_zukunftsbilder_content {
        textSubheadline
        textSummary
      }
      acf_content {
        repeaterDetails {
          textSectionTitel
          flexibleLayoutContentarea {
            ... on WpZukunftsbild_AcfContent_repeaterDetails_FlexibleLayoutContentarea_Link {
              fieldGroupName
              linkText
              linkUrl
            }
            ... on WpZukunftsbild_AcfContent_repeaterDetails_FlexibleLayoutContentarea_AbsatzMitGallerie {
              wysiwygContent
              groupGallery {
                galleryGallery {
                  altText
                  localFile {
                    childImageSharp {
                      fluid(quality: 60) {
                        src
                        srcSet
                      }
                    }
                    publicURL
                  }
                  acf_media {
                    textImageCredit
                  }
                }
                columns
              }
              linkText
              linkUrl
              fieldGroupName
            }
            ... on WpZukunftsbild_AcfContent_repeaterDetails_FlexibleLayoutContentarea_Absatz {
              wysiwygContent
              fieldGroupName
              linkText
              linkUrl
            }
            ... on WpZukunftsbild_AcfContent_repeaterDetails_FlexibleLayoutContentarea_VerwandteProjekte {
              fieldGroupName
              relationProject {
                ... on WpProjektziel {
                  id
                  slug
                  acf_projektziel_content {
                    groupLeft {
                      relationMobilitaetsschwerpunkt {
                        ... on WpSchwerpunkt {
                          id
                          slug
                        }
                      }
                      imageFeaturedImage {
                        altText
                        localFile {
                          childImageSharp {
                            fluid(quality: 60) {
                              src
                              srcSet
                            }
                          }
                          publicURL
                        }
                      }
                      textSummary
                      textProjektname
                    }
                    fieldGroupName
                  }
                }
              }
            }
            ... on WpZukunftsbild_AcfContent_repeaterDetails_FlexibleLayoutContentarea_VerwandteForschung {
              fieldGroupName
              relationForschung {
                ... on WpWissenschaft {
                  id
                  acf_forschung {
                    relationSchwerpunktthema {
                      ... on WpWissenschaftsschwerpunkt {
                        id
                        slug
                        title
                      }
                    }
                    projektbeschreibung {
                      fieldGroupName
                      textProjektbeschreibungText
                      textProjektbeschreibungTitle
                    }
                    imageFeatureImage {
                      altText
                      localFile {
                        childImageSharp {
                          fluid(quality: 60) {
                            src
                            srcSet
                          }
                        }
                        publicURL
                      }
                    }
                    groupProjektbeteiligte {
                      disziplin {
                        name
                      }
                    }
                  }
                  title
                  slug
                }
              }
            }
            ... on WpZukunftsbild_AcfContent_repeaterDetails_FlexibleLayoutContentarea_Registerkarten {
              fieldGroupName
              repeaterRegisterkarten {
                textContent
                textTitle
              }
            }
            ... on WpZukunftsbild_AcfContent_repeaterDetails_FlexibleLayoutContentarea_Download {
              fieldGroupName
              anhang {
                ... on WpZukunftsbild_AcfContent_repeaterDetails_FlexibleLayoutContentarea_Download_Anhang_Datei {
                  buttonText
                  fieldGroupName
                  datei {
                    localFile {
                      publicURL
                    }
                  }
                }
                ... on WpZukunftsbild_AcfContent_repeaterDetails_FlexibleLayoutContentarea_Download_Anhang_Video {
                  buttonText
                  fieldGroupName
                  video {
                    localFile {
                      publicURL
                    }
                  }
                }
                ... on WpZukunftsbild_AcfContent_repeaterDetails_FlexibleLayoutContentarea_Download_Anhang_Url {
                  buttonText
                  fieldGroupName
                  url
                }
              }
            }
          }
          fieldGroupName
        }
      }
    }
    wpModeSettings(
      slug: { eq: "zukunftsbild" }
      locale: { locale: { eq: $lang } }
    ) {
      acf_global_settings {
        textTocTitle
      }
      acf_zukunftsbilder_settings {
        textButtonBack
      }
    }
  }
`
